import React, { useState } from "react";
import useAxiosInstance from "../../hooks/useAxios";
import { toast } from "react-toastify";
import Input from "../../components/input";

const NewsletterReviver = () => {
  const [reportId, setReportId] = useState("");
  const [response, setResponse] = useState({});
  const axiosInstance = useAxiosInstance();

  return (
    <div>
      <div className="text-sm font-semibold mt-4 mb-2">Report ID</div>
      <Input
        value={Number(reportId)}
        type="number"
        onChange={(e) => {
          setReportId(e.target.value);
        }}
        placeholder="Enter Report ID"
      />
      <button
        onClick={()=>{
            axiosInstance
              .post("/newsletter/v2/get_remaining_topics/", {
                report_id: reportId,
              }).then(res=>setResponse(res.data.data))
        }} 
        className="px-3 py-2 bg-black text-white text-sm font-semibold mt-2 rounded-lg"
      >Get Remaining</button>
  
      <p className="mt-3 text-sm mb-2">Industry</p>
      <div className="p-2 bg-gray-200 rounded-xl">
        <pre
          style={{
            overflowY: "scroll",
            maxWidth: "100%",
            height: "100px",
            whiteSpace: "pre-wrap",
          }}
        >
          {JSON.stringify({
            report_id: reportId,
            source_type: "industry",
            topic: response?.["industry_left"] || [],
          })}
        </pre>
      </div>
      <button
        onClick={()=>{
            axiosInstance
              .post("/newsletter/start_sourcing_given_topic/", {
                report_id: Number(reportId),
                source_type: "industry",
                topic: response?.["industry_left"] || []
              }).then(res=>toast.success('Fired'))
        }} 
        className="px-3 py-2 bg-black text-white text-sm font-semibold mt-2 rounded-lg"
      >Fire industry</button>

      <p className="mt-3 text-sm mb-2">Company</p>
      <div className="p-2 bg-gray-200 rounded-xl">
        <pre
          style={{
            overflowY: "scroll",
            maxWidth: "100%",
            height: "100px",
            whiteSpace: "pre-wrap",
          }}
        >
          {JSON.stringify({
            report_id: reportId,
            source_type: "company",
            topic: response?.["company_left"] || [],
          })}
        </pre>
      </div>

      <button
        onClick={()=>{
            axiosInstance
              .post("/newsletter/start_sourcing_given_topic/", {
                report_id: Number(reportId),
                source_type: "company",
                topic: response?.["company_left"] || [],
              }).then(res=>toast.success('Fired'))
        }} 
        className="px-3 py-2 bg-black text-white text-sm font-semibold mt-2 rounded-lg"
      >Fire industry</button>
    </div>
  );
};

export default NewsletterReviver;
