import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../hooks/useAxios";
import { toast } from "react-toastify";
import { MdDelete, MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";

const AllPosts = () => {
  const axiosInstance = useAxiosInstance();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("assets/get_all_developers_posts/")
      .then((res) => {
        setIsLoading(false);
        setData(res.data.posts);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went while getting users");
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="p-6">
      <div className="text-2xl font-semibold items-center flex justify-between">All Developer updates 
        <Link to={'/addpost'}><button className="px-3 py-2 bg-black text-white font-medium text-sm rounded-lg">Add Post</button></Link>
      </div>
      {isLoading ? (
        <p className="mt-4">Loading...</p>
      ) : (
        <div className="p-2 overflow-x-auto bg-slate-100 mt-4 rounded-xl">
          <table className="w-full bg-slate-50 ">
            <thead>
              <th className="px-2 py-1 text-center">
                <p className="flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center">
                  Title
                </p>
              </th>
              <th className="px-2 py-1 text-center">
                <p className="flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center">
                  Description
                </p>
              </th>
              <th className="px-2 py-1">
                <p className="flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center">
                  Tag
                </p>
              </th>
              <th className="px-2 py-1 text-center">
                <p className="flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center">
                  Published date
                </p>
              </th>
              <th className="px-2 py-1 text-center">
                <p className="flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center">
                  Posted by
                </p>
              </th>
              <th className="px-2 py-1 text-center">
                <p className="flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center">
                  Button
                </p>
              </th>
              <th className="px-2 py-1 text-center">
                <p className="flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center">
                  Action
                </p>
              </th>
            </thead>
            <tbody className="mt-4 divide-y">
              {data?.map((post) => {
                return (
                  <tr
                    key={post.id}
                    className="p-2 rounded-xl border-b last:border-b-0 odd:bg-slate-100 even:bg-slate-50"
                  >
                    <td className="px-2 py-1">
                      <p className="flex justify-start  w-full">
                        <span className="ml-2 font-medium text-base"></span>
                        {post.title}
                      </p>
                    </td>
                    <td className="px-2 py-1">
                      <p className="flex justify-start  w-full">
                        <span className="ml-2 font-medium text-base"></span>
                        {post.description}
                      </p>
                    </td>
                    <td className="px-2 py-1">
                      <p className="flex justify-start  w-full">
                        <span className="ml-2 font-medium text-base"></span>
                        {post.tag}
                      </p>
                    </td>
                    <td className="px-2 py-1">
                      <p className="flex justify-start  w-full">
                        <span className="ml-2 font-medium text-base"></span>
                        {post.published_date}
                      </p>
                    </td>
                    <td className="px-2 py-1">
                      <p className="flex justify-start  w-full">
                        <span className="ml-2 font-medium text-base"></span>
                        {post.posted_by.username}
                      </p>
                    </td>
                    <td className="px-2 py-1">
                      <p className="flex justify-start  w-full">
                        <span className="ml-2 font-medium text-base"></span>
                        <a href={post.link_url}>
                          <button>{post.link_text}</button>
                        </a>
                      </p>
                    </td>
                    <td className="px-2 py-1">
                      <p className="flex justify-start  w-full">
                        <Link to={'/editpost/'+post.id}><button className="p-2 bg-yellow-300 rounded-lg"><MdEdit className="text-white"/></button></Link>
                        <button 
                        onClick={()=>{
                          axiosInstance
                          .delete(`assets/get_or_delete_delete_developer_post/?id=${post.id}`)
                          .then((res) => {
                              toast.success("Post deleted!")
                          })
                          .catch((err) => {
                              toast.error("Something went while deleting post");
                          })
                          setData(prev=>prev.filter(it=>it.id!==post.id))
                        }}
                        className="ml-2 p-2 bg-red-400 rounded-lg"><MdDelete className="text-white"/></button>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AllPosts;
