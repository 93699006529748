import React from "react";
import { useNavigate } from "react-router-dom";
import { options, typeEnmus } from "../helper";

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <div className="my-6 mr-4">
      <h1 className="text-2xl bg-white px-3 py-2 rounded-xl mb-4 shadow-sm border font-medium">
        Dashboard
      </h1>
      {Object.keys(typeEnmus).map((key) => {
        return (
          <div key={key}>
            <p className="text-base font-semibold py-2 px-3 my-4">
              {typeEnmus[key].label}
            </p>
            <div className="grid grid-cols-3 gap-x-3 gap-y-2">
              {options
                .filter((it) => it.type === typeEnmus[key].label)
                .map((elem) => {
                  return (
                    <button
                      key={elem.label}
                      onClick={() => {
                        navigate("/" + elem.value);
                      }}
                      className="px-3 py-3 flex items-center text-xl border gap-2 shadow-sm bg-white rounded-xl cursor-pointer"
                    >
                      {elem.icon} {elem.label}
                    </button>
                  );
                })}
            </div>
          </div>
        );
      })}
      <div className="grid grid-cols-3 gap-x-3 gap-y-2"></div>
    </div>
  );
};

export default Dashboard;
