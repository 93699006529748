import React from "react";

const Input = (props) => {
  const { value, onChange, type, placeholder , disabled, readOnly, onKeyDown=()=>{}} = props;
  if(type == 'text__area'){
    return <textarea
    disabled={disabled || false}
    readOnly = {readOnly || false}
    onKeyDown={onKeyDown}
    value={value}
    onChange={onChange}
    type={type || "text"}
    className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
    placeholder={placeholder}
  />
  }
  return (
    <input
      disabled={disabled || false}
      readOnly = {readOnly || false}
      onKeyDown={onKeyDown}
      value={value}
      onChange={onChange}
      type={type || "text"}
      className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
      placeholder={placeholder}
    />
  );
};

export default Input;
