import React, { useEffect, useState } from "react";
import Input from "../../components/input";
import { toast } from "react-toastify";
import useAxiosInstance from "../../hooks/useAxios";
import { parseAbsoluteToLocal } from "@internationalized/date";
import { DatePicker } from "@adobe/react-spectrum";
import { defaultTheme, Provider } from "@adobe/react-spectrum";

const EditPosts = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    tag: "",
    published_date: "2999-12-31T00:00:00Z",
    link_text: "",
    link_url: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const axiosInstance = useAxiosInstance();
  const id = window.location.pathname.split("/").pop();

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`assets/get_or_delete_delete_developer_post/?id=${id}`)
      .then((res) => {
        setIsLoading(false);
        setFormData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went while getting users");
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="p-6">
      <div className="text-2xl font-semibold items-center flex justify-between">
        Edit Developer updates
      </div>
      {isLoading ? (
        <p className="mt-4">Loading...</p>
      ) : (
        <div className="mt-4 p-4 border rounded-lg bg-white">
          <div className="text-sm font-semibold mb-2">Title</div>
          <Input
            value={formData.title}
            onChange={(e) => {
              setFormData({ ...formData, title: e.target.value });
            }}
            placeholder="Enter title"
          />
          <div className="text-sm font-semibold mt-4 mb-2">Description</div>
          <Input
            type="text__area"
            value={formData.description}
            onChange={(e) => {
              setFormData({ ...formData, description: e.target.value });
            }}
            placeholder="Enter description"
          />
          <div className="text-sm font-semibold mt-4 mb-2">Tag</div>
          <select
                        value={formData.tag}
                        onChange={(e) => {
                          setFormData({ ...formData, tag: e.target.value});
                        }}
                        className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
                        placeholder="Enter your password"
                      >
                        <option value={""}>Choose</option>
                        <option value={"API"}>API</option>
                        <option value={"Integrations"}>Integrations</option>
                        <option value={"Developer Portal"}>Developer Portal</option>
                      </select>
          
                      <div className="text-sm font-semibold mt-4 mb-2">Published date</div>
                      <Provider theme={defaultTheme}>
                        <DatePicker
                          onChange={(value) => {
                            setFormData({
                              ...formData,
                              published_date: value.toAbsoluteString(),
                            });
                            // console.log()
                          }}
                          defaultValue={parseAbsoluteToLocal(formData.published_date)}
                        />
                      </Provider>

          <div className="text-sm font-semibold mt-4 mb-2">Button text</div>
          <Input
            value={formData.link_text}
            onChange={(e) => {
              setFormData({ ...formData, link_text: e.target.value });
            }}
            placeholder="Enter button text"
          />

          <div className="text-sm font-semibold mt-4 mb-2">Button url</div>
          <Input
            value={formData.link_url}
            onChange={(e) => {
              setFormData({ ...formData, link_url: e.target.value });
            }}
            placeholder="Enter button url"
          />

          <button
            onClick={() => {
              setSaving(true);
              axiosInstance
                .post("assets/update_developer_post/", { id: id, ...formData })
                .then((res) => {
                  toast.success("Post updated!");
                  setSaving(false);
                })
                .catch((err) => {
                  console.log(err);
                  toast.error("Something went while getting users");
                  setSaving(false);
                });
            }}
            disabled={
              saving ||
              formData.description === "" ||
              formData.link_text === "" ||
              formData.link_url === "" ||
              formData.published_date === "" ||
              formData.tag === "" ||
              formData.title === ""
            }
            className="bg-black mt-4 text-white font-medium text-sm px-3 py-2 rounded-lg disabled:bg-[#b6b6b6]"
          >
            {saving ? "Saving..." : "Edit"}
          </button>
        </div>
      )}
    </div>
  );
};

export default EditPosts;