import React, { useEffect, useState } from "react";
import Input from "../../components/input";
import useAxiosInstance from "../../hooks/useAxios";
import JsonView from "@uiw/react-json-view";
import { MdCopyAll } from "react-icons/md";
import { toast } from "react-toastify";

const CosmosResults = () => {
  const [promptId, setPromptId] = useState(0);
  const [reportId, setReportId] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [container, setContainer] = useState("ai_prod_0");
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const axios = useAxiosInstance();
  
  useEffect(()=>{
    setPage(0)
  },[container, reportId, pageSize, promptId])

  const [res, setRes] = useState([]);
  const [perticularView, setPerticularView] = useState({});

  const handleSumit = () => {
    setLoading(true);
    axios
      .post("/assets/get_cosmos_results/", {
        prompt_id: promptId,
        report_id: reportId,
        container: container,
        page_size: Number(pageSize),
        page: page,
      })
      .then((res) => {
        setLoading(false);
        setRes(res.data.data || []);
        setPage((prev) => prev + 1);
      });
  };
  function parseCustomDate(dateString) {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    const hours = dateString.slice(8, 10);
    const minutes = dateString.slice(10, 12);
    const seconds = dateString.slice(12, 14);
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}
const saveTemplateAsFile = (filename, dataObjToWrite) => {
    const blob = new Blob([JSON.stringify(dataObjToWrite)], { type: "text/json" });
    const link = document.createElement("a");

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove()
};
  
  return (
    <div>
      <div className="mr-4  p-3 my-5 border rounded-lg bg-white">
        <p className="text-2xl text-black font-medium mb-4">Playground</p>
        <div className="grid grid-cols-2 gap-x-4">
        <div>
        <p className="text-sm font-semibold text-black mb-2">Prompt ID</p>
        <Input
          value={promptId}
          onChange={(e) => {
            setPromptId(Number(e.target.value));
          }}
          type="Number"
          placeholder="Enter the promptId"
        />
        </div>

        <div>
        <p className="text-sm font-semibold text-black  mb-2">Report ID</p>
        <Input
          value={reportId}
          onChange={(e) => {
            setReportId(Number(e.target.value));
          }}
          type="Number"
          placeholder="Enter the reportId"
        />
        </div>
        </div> 

        <div className="grid grid-cols-2 mt-4 gap-x-4">  
        <div>
        <p className="text-sm font-semibold text-black  mb-2">Page Size</p>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(e.target.value);
          }}
          className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
          placeholder="Enter your page size"
        >
          <option value={10}>10</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={500}>500</option>
          <option value={1000}>1000</option>
          <option value={9999999999999}>Full</option>
        </select>
        </div>

        <div>
        <p className="text-sm font-semibold text-black  mb-2">Container</p>
        <select
          value={container}
          onChange={(e) => {
            setContainer(e.target.value);
          }}
          className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
          placeholder="Enter your page size"
        >
          <option value={"ai_call"}>ai_call</option>
          <option value={"ai_call_2"}>ai_call_2</option>
          <option value={"ai_dev_0"}>ai_dev_0</option>
          <option value={"ai_prod_0"}>ai_prod_0</option>
          <option value={"ai_test_0"}>ai_test_0</option>
          <option value={"gpt3_call"}>gpt3_call</option>
          <option value={"user_log"}>user_log</option>
          <option value={"user_log_1"}>user_log_1</option>
        </select>
        </div>
        </div>


        <button
          onClick={() => {
            handleSumit();
          }}
          className="px-3 my-3 py-3 bg-black rounded-lg text-white disabled:bg-[#b6b6b6]"
        >
          Fire/Next
        </button>
        
        <div 
        onClick={()=>{
            saveTemplateAsFile('prompts.json', res)
          }}
        className="flex justify-end w-full cursor-pointer items-center gap-2">Download all prompts
        </div>
        <div 
        onClick={()=>{
            navigator.clipboard.writeText(JSON.stringify(res))
            toast.success('All prompts Copied')
          }}
        className="flex justify-end w-full cursor-pointer items-center gap-2"><MdCopyAll />Copy all
        </div>

        <div className="grid grid-cols-2 gap-2 ">
        <div>
          <div className="overflow-y-auto h-[60vh] bg-slate-100 p-1 rounded-lg w-full">
            <table className="w-full ">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm ">Prompt Id</th>
                <th className="px-2 py-1 text-gray-700 text-sm ">Report ID</th>
                <th className="px-2 py-1 text-gray-700 text-sm ">Date</th>
              </thead>
            <tbody className="divide-y">
              {loading ? (
                <div className="flex justify-center flex-col items-center">
                  <p>Loading...</p>
                </div>
              ) : (
                res.map((prompt) => {
                  return (
                    <tr className="cursor-pointer" onClick={() => {
                        setPerticularView(res.find(it=>it.id === prompt.id))
                    }}>
                      <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                        {prompt?.prompt_id}
                      </td>
                      <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                        {prompt?.report_id}
                      </td>
                      <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                        {parseCustomDate(prompt?.dt)}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
            </table>
          </div>
        </div>
        <div className="mt-3 h-[60vh] overflow-y-auto">
          <div className="flex justify-end w-full cursor-pointer"><MdCopyAll onClick={()=>{
            navigator.clipboard.writeText(JSON.stringify(perticularView))
            toast.success('This prompt Copied')
          }}/></div>
          <JsonView
            shortenTextAfterLength={0}
            value={perticularView}
            displayObjectSize={false}
            displayDataTypes={false}
          />
        </div>
        </div>
      </div>
    </div>
  );
};

export default CosmosResults;
